import { jsx as _jsx } from "react/jsx-runtime";
import { Editor } from "@tinymce/tinymce-react";
const traverseChildNodes = (childNodes, func) => {
    childNodes.forEach(child => {
        console.log(child);
        func(child);
        if (child.hasChildNodes()) {
            traverseChildNodes(child.childNodes, func);
        }
    });
};
const MailEditor = ({ imageUploadHandler, content, setFieldValue, }) => (_jsx(Editor, { apiKey: process.env.REACT_APP_TINY_KEY, value: content, init: {
        height: "calc(100vh - 166px)",
        contextmenu: false,
        plugins: "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons",
        menubar: "personalization file edit view insert format tools table help",
        toolbar: "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor codesample | ltr rtl",
        menu: {
            personalization: {
                title: "Personalization Field",
                items: "lead_company email first_name last_name job_title",
            },
        },
        templates: [
            {
                title: "Lead Company",
                description: "",
                content: "{lead_company}",
            },
            {
                title: "E-mail",
                description: "",
                content: "{email}",
            },
            {
                title: "First Name",
                description: "",
                content: "{first_name}",
            },
            {
                title: "Last Name",
                description: "",
                content: "{last_name}",
            },
            {
                title: "Job Title",
                description: "",
                content: "{job_title}",
            },
        ],
        automatic_uploads: true,
        file_picker_types: "image",
        images_upload_handler: imageUploadHandler,
        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        setup: function (editor) {
            editor.ui.registry.addMenuItem("lead_company", {
                text: "Lead Company",
                onAction: function () {
                    editor.insertContent("{lead_company}");
                },
            });
            editor.ui.registry.addMenuItem("email", {
                text: "E-mail",
                onAction: function () {
                    editor.insertContent("{email}");
                },
            });
            editor.ui.registry.addMenuItem("first_name", {
                text: "First name",
                onAction: function () {
                    editor.insertContent("{first_name}");
                },
            });
            editor.ui.registry.addMenuItem("last_name", {
                text: "Last name",
                onAction: function () {
                    editor.insertContent("{last_name}");
                },
            });
            editor.ui.registry.addMenuItem("job_title", {
                text: "Job title",
                onAction: function () {
                    editor.insertContent("{job_title}");
                },
            });
        },
        paste_postprocess: (_, args) => {
            console.log(args);
            traverseChildNodes(args.node.childNodes, (child) => {
                const msClassName = "MsoNormal";
                if ("className" in child && child.className === msClassName) {
                    child.className = child.className
                        .split(" ")
                        .filter(className => className !== msClassName)
                        .join(" ");
                }
            });
        },
    }, onEditorChange: newValue => {
        setFieldValue("content", newValue);
    } }));
export default MailEditor;
